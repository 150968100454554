/***************** Nav Bar CSS *****************/
.hotline-area {
    display: flex;
    align-items: center;
    gap: 20px !important;
}
.hotline-area .icon svg {
    fill: #63AB45;
}


.hotline-area .content {
    position: relative;
    line-height: 1;
}
.hotline-area .content span {
    font-family: "Rubik", sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 1;
    color: #000;
    margin-bottom: 5px;
    display: inline-block;
}
.hotline-area .content h6 a {
    font-family: "Rubik", sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    color: #63AB45;
    transition: .5s;
}
.hotline-area .content::after {
    content: "";
    height: 35px;
    width: 1px;
    background-color: rgba(16, 12, 8, .2);
    position: absolute;
    left: -10px;
    top: 50%;
    transform: translateY(-50%);
}
/****************************** Home Page CSS ************************************/



.home4-banner-area {
    position: relative;
    background-image: url(../Images/home4/home4-banner-bg.png), linear-gradient(180deg, #ece4d7 0%, #ece4d7 100%);
    background-size: cover;
    background-repeat: no-repeat;
    padding: 25px;
}
.home4-banner-area .banner-wrapper {
    padding: 20px 20px 20px 40px;
    border: 1px dashed rgba(16, 12, 8, .5);
    position: relative;
}
.home1-banner-bottom.style-4 {
    margin-top: 0;
    padding: 0;
}
.home1-banner-bottom {
    margin-top: -100px;
    z-index: 99;
    position: relative;
    padding: 0 70px;
}
.home4-banner-area .banner-wrapper .social-list {
    position: absolute;
    top: 50%;
    right: -12px;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 45px;
    background-color: #ece4d7;
    z-index: 1;
    padding: 50px 0 90px;
}
.home4-banner-area .banner-wrapper .social-list li {
    position: relative;
    line-height: 1;
    transition: .5s;
}
.bx {
    font-family: boxicons !important;
    font-weight: 400;
    font-style: normal;
    font-variant: normal;
    line-height: 1;
    text-rendering: auto;
    display: inline-block;
    text-transform: none;
    speak: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
i.bx {
    vertical-align: middle;
}
.home4-banner-area .banner-wrapper .social-list li a i {
    color: var(--title-color);
    font-size: 22px;
    transition: .5s;
}
.bxl-facebook:before {
    content: "\e926";
}
.home4-banner-area .banner-wrapper .airplane-icon {
    position: absolute;
    top: 19%;
    right: -10px;
    z-index: 1;
}
.home4-banner-area .banner-wrapper .airplane-icon svg {
    fill: var(--title-color);
}
.home4-banner-area .banner-wrapper .banner-slider-btn-area {
    padding: 10px;
    background-image: url(../Images/home4/banner-slider-btn-bg.png);
    overflow: hidden;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: end;
    justify-content: end;
    position: absolute;
    right: -1px;
    bottom: -10px;
    /* z-index: 99; */
}
.home4-banner-area .banner-wrapper .banner-slider-btn-area .banner-slider-btn-grp {
    display: flex;
    align-items: center;
    gap: 45px;
    position: relative;
}
.home4-banner-area .banner-wrapper .banner-slider-btn-area .banner-slider-btn-grp .slider-btn {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid #152f00;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .35s;
}
.home4-banner-area .banner-wrapper .banner-slider-btn-area .banner-slider-btn-grp .slider-btn i {
    transition: .35s;
    font-size: 18px;
    color: #152f00;
}
.bi::before, [class*=" bi-"]::before, [class^=bi-]::before {
    display: inline-block;
    font-family: bootstrap-icons !important;
    font-style: normal;
    font-weight: 400 !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    vertical-align: -.125em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.home4-banner-area .banner-wrapper .banner-content-wrap .banner-content h1 {
    color: #000;
    font-family:"Rubik", sans-serif;
    font-size: 55px;
    font-weight: 800;
    line-height: 1.2;
    margin-bottom: 20px;
}
.home4-banner-area .banner-wrapper .banner-content-wrap .banner-content p {
    color: var(--text-color);
    font-family: var(--font-jost);
    font-size: 16px;
    font-weight: 400;
    line-height: 1.8;
    letter-spacing: .48px;
    text-transform: capitalize;
    margin-bottom: 20px;
}
.home4-banner-area .banner-wrapper .banner-content-wrap .mb-40 {
    margin-bottom: 40px;
}
.home1-banner-bottom.style-4 {
    margin-top: 0;
    padding: 0;
}
.home4-banner-area .banner-wrapper .banner-img {
    -webkit-clip-path: polygon(100% 0, 100% 70%, 70% 100%, 0 100%, 0 0);
    clip-path: polygon(100% 0, 100% 70%, 70% 100%, 0 100%, 0 0);
    overflow: hidden;
    position: relative;
}
.home4-banner-area .banner-wrapper .banner-img::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(16, 12, 8, .3);
}
.home4-banner-area .banner-wrapper .banner-img img {
    min-height: 570px;
    -o-object-fit: cover;
    object-fit: cover;
}
.home4-banner-img-slider .swiper-slide .carousel-control-prev,.home4-banner-img-slider .swiper-slide .carousel-control-next,.home4-banner-img-slider .swiper-slide .carousel-indicators{
    display: none;
}
.home4-banner-area .banner-wrapper .social-list li:hover a i {
    color: var(--primary-color1);
}
.home4-banner-area .banner-wrapper .social-list li a i {
    color: #000;
    font-size: 22px;
    transition: .5s;
}
.home4-banner-area .banner-wrapper .social-list li::before {
    content: "";
    position: absolute;
    top: -22px;
    left: 50%;
    transform: translateX(-50%);
    background: #000;
    width: 3px;
    height: 3px;
    border-radius: 50%;
}
.mb-120 {
    margin-bottom: 120px;
}
.pt-120 {
    padding-top: 120px;
}
.mb-60 {
    margin-bottom: 60px;
}
.section-title3 {
    max-width: 705px;
    width: 100%;
}
.section-title3 h2 {
    color: #000;
    font-family: "Rubik", sans-serif;
    font-size: 38px;
    font-weight: 600;
    line-height: 1.2;
    margin-bottom: 0;
    position: relative;
    display: inline-block;
}
.section-title3 h2::after {
    content: "";
    position: absolute;
    bottom: 5px;
    left: 0;
    background-color: rgba(99, 171, 69, 0.15);
    border-radius: 20px;
    width: 100%;
    height: 15px;
}
.section-title3 p {
    color: var(--text-color);
    font-family: "Rubik", sans-serif;
    font-size: 17px;
    font-weight: 400;
    line-height: 1.8;
    letter-spacing: .51px;
    margin-bottom: 0;
    padding-top: 15px;
}
.destination-card2 {
    position: relative;
    display: block;
    transition: .5s;
}
.destination-card2 .destination-card-img {
    position: relative;
    display: block;
    height: 30em;
    object-fit: cover;
    overflow: hidden;
    border-radius: 10px;
    transition: .5s;
}
.destination-card2.style-2 .destination-card-img::before {
    background: linear-gradient(180deg, rgba(16, 12, 8, 0) 0%, rgba(16, 12, 8, 0.63) 100%);
    border-radius: 5px;
}
.destination-card2.style-2 .destination-card-img img {
    border-radius: 5px;
}
.destination-card2.style-2 .destination-card2-content-wrap {
    position: absolute;
    text-align: center;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: 15px 20px 25px;
}
.destination-card2.style-2 .destination-card2-content-wrap .eg-batch {
    display: inline-flex;
    justify-content: center;
    opacity: 0;
    transform: scaleY(0);
    transform-origin: top;
    transition: .5s ease-in-out;
}
.destination-card2.style-2 .destination-card2-content-wrap .eg-batch .location {
    border-radius: 38px;
    border: 1px solid rgba(255, 255, 255, .3);
    background: rgba(16, 12, 8, .2);
    -webkit-backdrop-filter: blur(2.5px);
    backdrop-filter: blur(2.5px);
    padding: 18px 25px;
}
.destination-card2.style-2 .destination-card2-content-wrap .eg-batch .location .location-list {
    display: flex;
    align-items: center;
    gap: 16px;
}
.destination-card2.style-2 .destination-card2-content-wrap .eg-batch .location .location-list li {
    position: relative;
    color: #fff;
    font-family: "Rubik", sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 1;
    letter-spacing: .6px;
    text-transform: uppercase;
}
.destination-card2.style-2 .destination-card2-content-wrap .eg-batch .location .location-list li::before {
    content: "\f4fe";
    font-family: bootstrap-icons;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -17px;
    color: var(--white-color);
    font-size: 17px;
}
.destination-card2.style-2 .destination-card2-content-wrap .destination-card2-content {
    position: relative;
    bottom: unset;
    left: unset;
    text-align: center;
}
.destination-card2 .destination-card2-content span {
    color: #63AB45;
    font-family: "Rubik", sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    letter-spacing: .28px;
    text-transform: capitalize;
    display: inline-block;
    margin-bottom: 5px;
}
.home4-about-section .about-content .section-title3 h2 span {
    color: #63AB45;
}
.destination-card2 .destination-card2-content h4 {
    margin-bottom: 0;
    line-height: 1.1;
    font-family: "Rubik", sans-serif;
}
.destination-card2 .destination-card2-content h4 a {
    color: #fff;
    font-family: "Rubik", sans-serif;
    font-size: 28px;
    /* font-weight: 500; */
    line-height: 1.1;
    text-transform: capitalize;
    transition: .5s;
}
.destination-card2.style-2 .destination-card-img::before {
    background: linear-gradient(180deg, rgba(16, 12, 8, 0) 0%, rgba(16, 12, 8, 0.63) 100%);
    border-radius: 5px;
}
.destination-card2 .destination-card-img::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(16, 12, 8, 0) 0%, rgba(16, 12, 8, 0.8) 100%);
    border-radius: 10px;
    z-index: 1;
}
.destination-card2 .destination-card2-content {
    position: absolute;
    bottom: 25px;
    left: 25px;
    line-height: 1;
    z-index: 2;
}
.primary-btn1 {
    border-radius: 5px;
    background-color:#63AB45;
    font-family: "Rubik", sans-serif;
    font-weight: 600;
    font-size: 16px;
    color: #fff;
    letter-spacing: .48px;
    text-transform: capitalize;
    line-height: 1;
    padding: 16px 34px;
    display: inline-flex;
    align-items: center;
    gap: 10px;
    transition: .5s;
    position: relative;
    overflow: hidden;
    z-index: 1;
    white-space: nowrap;
}
.mb-50 {
    margin-bottom: 50px;
}
.home4-about-section .about-img-wrap {
    position: relative;
    width: 100%;
    height: 100%;
}
.home4-about-section .about-img-wrap .about-img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    -o-object-fit: cover;
    object-fit: cover;
}
.home4-about-section .about-img-wrap .shape {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #fff;
    mix-blend-mode: screen;
}
.no-after h2::after{
    content: none;
}
.home4-about-section .about-content .author-area {
    display: flex;
    align-items: center;
    gap: 30px;
    margin-bottom: 30px;
}
.home4-about-section .about-content .author-area .author-img img {
    min-width: 120px;
    max-width: 120px;
    height: 120px;
    border-radius: 50%;
}
.home4-about-section .about-content .section-title3 {
    max-width: unset;
    margin-bottom: 30px;
}
.home4-about-section .about-content .author-area .author-content p {
    color: #787878;
    font-family: "Jost", sans-serif;
    font-size: 17px;
    font-weight: 400;
    line-height: 1.9;
    letter-spacing: .51px;
    margin-bottom: 0;
}
.home4-about-section .about-content .service-list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 35px;
}
.home4-about-section .about-content .service-list li {
    display: flex;
    align-items: center;
    gap: 8px;
    color: #000;
    font-family: "Jost", sans-serif;
    font-size: 17px;
    font-weight: 500;
    line-height: 1.4;
    letter-spacing: .51px;
}
.home4-about-section .about-content .service-list li svg {
    fill: #63AB45;
}
.home4-about-section .about-content .single-counter {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 17px 25px;
    border-radius: 10px;
    background: rgba(99, 171, 69, 0.1);
    -webkit-backdrop-filter: blur(32px);
    backdrop-filter: blur(32px);
    position: relative;
    overflow: hidden;
}
.home4-about-section .about-content .single-counter .star {
    position: absolute;
    top: 0;
    right: 0;
}
.home4-about-section .about-content .single-counter .star svg {
    fill: #FBB03B;
}
.home4-about-section .about-content .single-counter .icon svg {
    fill:#63AB45;
}
.home4-about-section .about-content .single-counter .content .number {
    display: flex;
    align-items: center;
    font-family: "Rubik", sans-serif;
    font-weight: 700;
    line-height: 1;
    color: #63AB45;
    margin-bottom: 4px;
}
.home4-about-section .about-content .single-counter .content .number h5 {
    color: #63AB45;
    font-size: 36px;
    margin-bottom: 0;
    line-height: 1;
}
.home4-about-section .about-content .single-counter .content .number span {
    font-size: 36px;
    display: inline-block;
    line-height: 1;
}
.home4-about-section .about-content .single-counter .content p {
    margin-bottom: 0;
    color: #000;
    line-height: 22px;
    font-size: 16px;
    font-weight: 500;
}


.home4-banner2-area .home4-banner2-wrapper {
    background-image: url(../Images/home4/home4-banner2-bg.png), linear-gradient(180deg, #1d231f 0%, #1d231f 100%);
    background-size: cover;
    background-repeat: no-repeat;
    padding: 0 0 0 50px;
    border-radius: 10px;
    overflow: hidden;
}
.home4-banner2-area .home4-banner2-wrapper .home4-banner2-content {
    text-align: center;
}
.home4-banner2-area .home4-banner2-wrapper .home4-banner2-content span {
    color:#63AB45;
    font-family: "Satisfy", sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 1;
    display: inline-block;
    margin-bottom: 20px;
}
.home4-banner2-area .home4-banner2-wrapper .home4-banner2-content h2 {
    color: #fff;
    font-family: "Rubik", sans-serif;
    font-size: 42px;
    font-weight: 700;
    line-height: 1.1;
}
.home4-banner2-area .home4-banner2-wrapper .home4-banner2-content h2 strong {
    color: #FBB03B;
    font-size:38px;
}
.home4-banner2-area .home4-banner2-wrapper .home4-banner2-content p {
    color: #fff;
    font-family: "Jost", sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.7;
    letter-spacing: .54px;
    text-transform: capitalize;
    max-width: 420px;
    width: 100%;
    margin: 0 auto 35px;
}
.package-card3 .package-card-content .card-content-top h5{
    color: #000;
    font-family: "Rubik", sans-serif;
    font-size: 22px;
    font-weight: 500;
    line-height: 1.4;
    text-transform: capitalize;
    transition: .5s;
}
.package-card3.style-4 .package-card-content .card-content-bottom {
  
    border-top: 1px solid rgba(16, 12, 8, .06);
}
.package-card3 .package-card-content .card-content-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    padding: 20px 25px;
    border-top: 1px solid rgba(34, 34, 34, .2);
}

.package-card3 .package-card-content .card-content-bottom .price-area {
    line-height: 1;
}
.package-card3 .package-card-content .card-content-bottom .price-area .title {
    color: #63AB45;
    font-family:  "Rubik", sans-serif;
    font-size: 13px;
    font-weight: 500;
    line-height: 1;
    letter-spacing: .26px;
    text-transform: capitalize;
    display: inline-block;
    margin-bottom: 5px;
}
.package-card3.style-4 .package-card-content .card-content-bottom .price-area h6 {
    font-size: 20px;
    font-weight: 800;
    color: #63AB45;
    font-family:"Rubik", sans-serif;
}
.package-card3 .package-card-content .card-content-bottom .price-area span {
    color: #000;
    font-family:"Rubik", sans-serif;
    font-size: 11px;
    font-weight: 400;
    line-height: 1;
    letter-spacing: .22px;
    text-transform: capitalize;
}
.package-card3 .package-card-content .card-content-bottom .price-area h6 sub {
    font-size: 17px;
    font-weight: 600;
    bottom: 0;
}
.promotion-card-devider{
    border-top: 1px solid rgba(16, 12, 8, .06);
    border-bottom: 1px solid rgba(16, 12, 8, .06);
}
.package-card3 .package-card-content .card-content-bottom1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    padding: 20px 25px;
}
.package-card3 .package-card-content .card-content-bottom1 .price-area {
    line-height: 1;
}
.package-card3 .package-card-content .card-content-bottom1 .price-area .title {
    color: #63AB45;
    font-family:  "Rubik", sans-serif;
    font-size: 13px;
    font-weight: 500;
    line-height: 1;
    letter-spacing: .26px;
    text-transform: capitalize;
    display: inline-block;
    margin-bottom: 5px;
}
.package-card3.style-4 .package-card-content .card-content-bottom1 .price-area h6 {
    font-size: 20px;
    font-weight: 800;
    color: #63AB45;
    font-family:"Rubik", sans-serif;
}
.package-card3 .package-card-content .card-content-bottom1 .price-area span {
    color: #000;
    font-family:"Rubik", sans-serif;
    font-size: 11px;
    font-weight: 400;
    line-height: 1;
    letter-spacing: .22px;
    text-transform: capitalize;
}
.package-card3 .package-card-content .card-content-bottom1 .price-area h6 sub {
    font-size: 17px;
    font-weight: 600;
    bottom: 0;
}
.package-card3.style-4 {
    border: 1px solid rgba(16, 12, 8, .08);
    box-shadow: unset;
    padding: 5px;
    padding: 0;
    transition: .5s;
}
.package-card3 {
    border-radius: 5px;
    background-color: var(--white-color);
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, .08);
    position: relative;
    overflow: hidden;
}
.package-card3 .package-card-content .rating-area {
    display: flex;
    align-items: center;
    gap: 8px;
    line-height: 1;
    margin-bottom: 15px;
}
.package-card3 .package-card-content .rating-area .rating {
    display: flex;
    align-items: center;
    gap: 4px;
}
.package-card3 .package-card-content .rating-area .rating li i {
    color: #ffc200;
    font-size: 12px;
}
.banner4-card {
    position: relative;
    height: auto;
}
.banner4-card img {
    border-radius: 10px;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    height: 20em;
    width: -webkit-fill-available;
}
.banner4-card.four .banner4-content-wrapper {
    background: linear-gradient(87deg, #BC6C25 2.1%, rgba(188, 108, 37, 0.97) 16.21%, rgba(188, 108, 37, 0.95) 27.22%, rgba(188, 108, 37, 0) 62.92%);
}
.banner4-card .banner4-content-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(88deg, #4C7CED 1.75%, rgba(76, 124, 237, 0.97) 24.14%, rgba(76, 124, 237, 0.95) 41.61%, rgba(76, 124, 237, 0) 98.26%);
    width: 100%;
    height: 100%;
    border-radius: 10px;
    display: flex;
    align-items: center;
}
.banner4-card .banner4-content-wrapper .banner4-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 20px;
}
.banner4-card .banner4-content-wrapper .banner4-content span {
    color: #fff;
    font-family:"Satisfy", sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 1;
    display: inline-block;
    margin-bottom: 15px;
}
.banner4-card.four .banner4-content-wrapper .banner4-content h3 {
    color: #000;
    font-size: 35px;
}
.banner4-card .banner4-content-wrapper .banner4-content h3 {

    font-family: "Rubik", sans-serif;
    font-size: 35px;
    font-weight: 700;
    line-height: 1;
    margin-bottom: 10px;
}
.banner2-card.five::before {
    border-radius: 10px;
}
.banner2-card::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(16, 12, 8, .2);
    border-radius: 5px;
}
.banner2-card.five img {
    border-radius: 10px;
    width: -webkit-fill-available;
    height: 20em;
    object-fit: cover;

}
.banner2-card.five .banner2-content-wrap {
    background-image: url(../Images/home4/banner2-content-bg2.svg);
    padding: 38px 20px 38px 25px;
    text-align: center;
    right: 0;
    left: unset;
    background-size: contain;
    background-position: right center;
    border-radius: 0 10px 10px 0;
    height: 100%;
}
.banner2-card {
    position: relative;
    border-radius: 5px;
}
.banner2-card .banner2-content-wrap {
    padding: 60px 20px 35px;
    background-image: url(../Images/home4/banner2-content-bg2.svg);
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    border-radius: 0 0 5px 5px;
}
.banner2-card.five .banner2-content-wrap .banner2-content {
    max-width: 250px;
    width: 100%;
    text-align: center;
}
.banner2-card .banner2-content-wrap .banner2-content>span {
    color: #fff;
    font-family: "Satisfy", sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 1;
    display: block;
    margin-bottom: 16px;
}
.banner2-card.five .banner2-content-wrap .banner2-content h5 span {
    font-size: 35px;
    font-weight: 700;
    color: #ffc200;
}
.banner2-card.five .banner2-content-wrap .banner2-content h5 {
    color: #fff;
    font-family: "Rubik", sans-serif;
    font-size: 25px;
    font-weight: 600;
    line-height: 1.4;
    margin-bottom: 15px;
}
.banner4-card .banner4-content-wrapper .banner4-content .text {
    color: #fff;
    font-family: "Rubik", sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.6;
    display: block;
    margin-bottom: 20px;
}
.feature-card2 {
    padding: 30px 20px;
    border-radius: 5px;
    border: 1px solid rgba(16, 12, 8, .1);
    background-color:#fff;
    transition: .5s;
}
.feature-card2 .feature-card-top {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 25px;
}
.feature-card2 .feature-card-top .icon svg {
    fill: #63AB45;
    transition: .5s;
}
.feature-card2 .feature-card-top .title h4 {
    color:#000;
    font-family: "Rubik", sans-serif;
    font-size: 23px;
    font-weight: 500;
    line-height: 1.2;
    letter-spacing: .69px;
    text-transform: capitalize;
    margin-bottom: 0;
    max-width: 235px;
    width: 100%;
    transition: .5s;
}
.feature-card2 .feature-card-content p {
    color: #787878;
    font-family: "Jost", sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 1.9;
    letter-spacing: .45px;
    margin-bottom: 0;
    transition: .5s;
}
.home4-activity-counter-section {
    background-image: linear-gradient(180deg, rgba(16, 12, 8, 0.6) 0%, rgba(16, 12, 8, 0.6) 100%), url(../Images/home4/activity-counter-bg.png);
    background-size: cover;
    background-repeat: no-repeat;
    padding: 90px 0;
}
.single-activity.two {
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    background: rgba(255, 255, 255, .15);
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
    padding: 20px;
    transition: .5s;
}
.single-activity {
    display: flex;
    align-items: center;
    gap: 20px;
}
.single-activity.two .icon {
    margin-bottom: 20px;
}
.single-activity .icon svg {
    fill: #63AB45;
}
.single-activity.two .content .number {
    display: flex;
    align-items: center;
    font-family:  "Rubik", sans-serif;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    margin-bottom: 4px;
}
.single-activity .content p {
    margin-bottom: 0;
    color: var(--text-color);
    line-height: 22px;
    font-size: 16px;
    font-weight: 400;
}
.single-activity.two .content p {
    color: rgba(255, 255, 255, .6);
}
.single-activity.two .content .number h5 {
    font-size: 35px;
    color:#fff;
    margin-bottom: 0;
    line-height: 1;
}
.single-activity.two .content .number span {
    font-size: 35px;
    display: inline-block;
    line-height: 1;
}
.footer-section {
    background-image: url(../Images/home4/footer-bg.png), linear-gradient(180deg, #1d231f 0%, #1d231f 100%);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    position: relative;
}
.footer-section.style-2 .footer-top {
    padding: 120px 0;
}
.footer-section .footer-bottom {
    border-top: 1px solid rgba(255, 255, 255, .1);
    padding: 20px 0;
}
.footer-section .footer-bottom p {
    color: rgba(255, 255, 255, .5);
    font-family: "Rubik", sans-serif;
    font-size: 14px;
    font-weight: 300;
    line-height: normal;
    letter-spacing: .42px;
    margin-bottom: 0;
}
.footer-section .footer-bottom .footer-right ul {
    display: flex;
    align-items: center;
    gap: 35px;
}
.footer-section .footer-bottom .footer-right ul li {
    position: relative;
}
.footer-section .footer-bottom .footer-right ul li a {
    color: rgba(255, 255, 255, .6);
    font-family: "Jost", sans-serif;
    font-size: 15px;
    font-weight: 500;
    line-height: 1;
    transition: .5s;
}
.footer-section .footer-top .footer-widget h3 {
    color: #fff;
    font-family:"Rubik", sans-serif;
    font-size: 20px;
    font-weight: 600;
    line-height: 1.6;
    text-transform: capitalize;
    width: 100%;
    margin-bottom: 25px;
}
.footer-section .footer-top .footer-widget .widget-title {
    display: flex;
    align-items: center;
    gap: 7px;
    margin-bottom: 20px;
}
.footer-section .footer-top .footer-widget .widget-title h5 {
    color: rgba(255, 255, 255, .8);
    font-family: "Rubik", sans-serif;
    font-size: 20px;
    font-weight: 600;
    line-height: 1;
    letter-spacing: .66px;
    margin-bottom: 0;
}
.footer-section .footer-top .footer-widget .widget-list {
    margin: 0;
    padding: 0;
    list-style: none;
}
.footer-section .footer-top .footer-widget .widget-list li {
    margin-bottom: 10px;
    transition: .5s;
}
.footer-section .footer-top .footer-widget .widget-list li a {
    color: rgba(255, 255, 255, .6);
    font-family: "Jost", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 1;
    letter-spacing: .32px;
    transition: .5s;
}
.footer-section .footer-top .footer-widget .widget-title svg {
    fill: #ffc200;
}
.footer-section .footer-top .footer-widget .single-contact a {
    color: rgba(255, 255, 255, .6);
    font-family:"Jost", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: .48px;
    transition: .5s;
}
.mb-30 {
    margin-bottom: 30px;
}
.footer-section .footer-top .footer-widget .payment-partner .icons ul {
    display: flex;
    align-items: center;
    gap: 20px;
}
.footer-section .footer-top .footer-widget .payment-partner .icons ul li img {
    border-radius: 4px;
}
.home4-banner-area .banner-wrapper .social-list li a svg {
    fill: #000;
}
.home4-banner-area .banner-wrapper .banner-content-wrap .mb-40 {
    margin-bottom: 40px;
}
.home1-banner-bottom.style-4 .filter-wrapper .nav-buttons {
    justify-content: center;
    /* margin-left: 30px; */
    margin-bottom: -20px;
}
.home1-banner-bottom .filter-wrapper .nav-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
}
.home1-banner-bottom.style-4 .filter-wrapper .nav-buttons .nav-pills {
    background-color: #ece4d7;
    border-radius: 10px;
    border: 1px solid rgba(16, 12, 8, .15);
}
.home1-banner-bottom .filter-wrapper .nav-buttons .nav-pills {
    border-radius: 15px 15px 0 0;
    background: #edf2de;
    display: inline-flex;
    border-bottom: 1px solid #dbe1cd;
}
.home1-banner-bottom.style-4 .filter-wrapper .nav-buttons .nav-pills .nav-item:first-child .nav-link {
    border-radius: 10px 0 0 10px;
}
.home1-banner-bottom.style-4 .filter-wrapper .nav-buttons .nav-pills .nav-item .nav-link {
    border-right: 1px solid rgba(16, 12, 8, .15);
    font-size: 15px;
    padding: 11px 20px;
}
.home1-banner-bottom .filter-wrapper .nav-buttons .nav-pills .nav-item .nav-link.active {
    background-color: #da4aa2;
    color: #fff !important;
}
.home1-banner-bottom .filter-wrapper .nav-buttons .nav-pills .nav-item:first-child .nav-link {
    border-radius: 15px 0 0 0;
    border-left: unset;
}
.home1-banner-bottom .filter-wrapper .nav-buttons .nav-pills .nav-item .nav-link {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    color: var(--title-color);
    font-family: "Rubik", sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 1;
    padding: 15px 40px;
    border-radius: unset;
    border-left: 1px solid #dbe1cd;
    width: 100%;
}
.home1-banner-bottom .filter-wrapper .nav-buttons .nav-pills .nav-item .nav-link.active svg {
    fill: #fff;
}
.home1-banner-bottom.style-4 .filter-wrapper .nav-buttons .nav-pills .nav-item:last-child .nav-link {
    border-radius: 0 10px 10px 0;
    border-right: unset;
}
.home1-banner-bottom.style-4 .filter-wrapper .filter-group {
    background-color: transparent;
    border: 1px solid rgba(16, 12, 8, .15);
    border-radius: 20px;
    padding: 35px 10px 25px;
}
.home1-banner-bottom .filter-wrapper .filter-group {
    border-radius: 0 0 30px 30px;
    background: #edf2de;
}

.filter-group .css-13cymwt-control,.select-styling{
    background: none;
    height: auto !important;
}
.filter-group .rs-picker-toggle{
    background:none !important;
   
}
.filter-group .search-wrap .btn{
    height: auto !important;
}


/******************** About us page **************************/

.mb-90 {
    margin-bottom: 90px;
}
.section-title2 h2 {
    color: #000;
    font-family: "Rubik", sans-serif;
    font-size: 45px;
    font-weight: 600;
    line-height: 1.1;
    margin-bottom: 0;
}
.section-title2 p {
    font-family:"Jost", sans-serif;
    font-size: 17px;
    font-weight: 400;
    line-height: 1.9;
    letter-spacing: .51px;
    margin-bottom: 0;
    padding-top: 30px;
}
.home2-about-section .about-content .facility-card {
    background-color: #00800014;
    border-radius: 10px;
    padding: 13px 22px;
    display: flex;
    align-items: center;
    gap: 15px;
    transition: .5s;
}
.home2-about-section .about-content .facility-card .icon {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    background-color: #fff;
    filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.05));
    display: flex;
    align-items: center;
    justify-content: center;
}
.home2-about-section .about-content .facility-card .content h6 {
    color: #000;
    font-family: "Rubik", sans-serif;
    font-size: 17px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: .34px;
    text-transform: capitalize;
    margin-bottom: 0;
    transition: .5s;
}
.home2-about-section .about-img-wrap {
    position: relative;
}
.activities-counter .single-activity .content .number {
    display: flex;
    align-items: center;
    font-family: "Rubik", sans-serif;
    font-weight: 700;
    line-height: 1;
    color: #000;
    margin-bottom: 4px;
}

.activities-counter .single-activity .content p {
    margin-bottom: 0;
    color: #787878;
    line-height: 22px;
    font-size: 16px;
    font-weight: 400;
}
.activities-counter .divider::after {
    content: "";
    height: 41px;
    width: 1px;
    background-color: rgba(19, 20, 26, .16);
    position: absolute;
    right: 0;
    top: 50%;
    left: unset;
    transform: translateY(-50%);
}
.activities-counter {
    padding: 40px 0;
    border-bottom: 1px solid rgba(16, 12, 8, .1);
    border-top: 1px solid rgba(16, 12, 8, .1);
}
.feature-card.style-2 {
    background-color: #fff;
    background-image: unset;
    border: unset;
    box-shadow: 0 0 20px -2px rgba(0, 0, 0, .08);
    padding: 25px 28px;
    display: flex;
    align-items: center;
    gap: 20px
}
.feature-card.style-2 .feature-card-icon svg {
    fill: #63AB45;
    transition: .5s;
}
.feature-card .feature-card-content h6 {
    color: #000;
    font-family: "Rubik", sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.4;
    letter-spacing: .6px;
    text-transform: capitalize;
    margin-bottom: 12px;
    transition: .5s;
}
.contact-page .single-contact {
    border: 1px solid #eee;
    border-radius: 10px;
    padding: 20px 50px;
    display: flex;
    align-items: center;
    gap: 15px;
    position: relative;
}
.contact-page .single-contact .title {
    line-height: 1;
    background-color: #fff;
    position: absolute;
    left: 35px;
    top: -8px;
    padding: 0 20px;
}
.contact-page .single-contact .title h6 {
    color: #000;
    font-family:"Rubik", sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    margin-bottom: 0;
}
.contact-page .single-contact .icon {
    height: 44px;
    min-width: 44px;
    max-width: 44px;
    border-radius: 50px;
    background-color: #63AB45;
    display: flex;
    align-items: center;
    justify-content: center;
}
.contact-page .single-contact .icon svg {
    fill: #fff;
}
.contact-page .single-contact .content {
    line-height: 1;
}
.contact-page .single-contact .content h6 {
    margin-bottom: 0;
}
.contact-page .single-contact .content h6 a {
    color: #000;
    font-family:"Rubik", sans-serif;
    font-size: 16px;
    font-weight: 500;
    padding-top: 10px;
    transition: .35s;
}
.contact-page .single-contact {
    padding: 20px 30px;
}
.mb-40 {
    margin-bottom: 40px;
}
.contact-form-area {
    background: linear-gradient(125deg, rgba(99, 171, 69, 0.1) 0%, rgba(251, 176, 59, 0.1) 100%);
    border-radius: 10px;
    padding: 60px 70px;
}
.contact-form-area {
    padding: 60px 30px;
}
.contact-form-area h3 {
    font-size: 30px;
    font-family:"Rubik", sans-serif;
    font-weight: 700;
    margin-bottom: 20px;
}
.mb-20 {
    margin-bottom: 20px;
}
.form-inner {
    line-height: 1;
}
.form-inner label {
    color: #5d5b58;
    font-family: "Rubik", sans-serif;
    font-size: 13px;
    font-weight: 500;
    display: inline-block;
    line-height: 1;
    margin-bottom: 7px;
}
.form-inner input {
    width: 100%;
    border-radius: 0;
    background: #fff;
    color: rgba(255, 255, 255, .6);
    font-family:"Jost", sans-serif;
    font-size: 13px;
    font-weight: 400;
    padding: 10px 20px;
    height: 50px;
    border: 1px solid #eee;
}
.form-inner textarea {
    width: 100%;
    border-radius: 5px;
    background: #fff;
    color: #5e5e5e;
    border: 1px solid #eee;
    outline: none;
    font-family: "Jost", sans-serif;
    font-size: 13px;
    font-weight: 400;
    padding: 20px 25px;
    min-height: 150px;
}