.logo-PK{
    background-image: url(../../Images/airlineslogo/PK.png);
    background-repeat: no-repeat;
    height: 3em;
    width: 6em;
    margin-top: 0.3em;
   
  }
  .logo-QR{
    background-image: url(../../Images/airlineslogo/QR.png);
    background-repeat: no-repeat;
    height: 2em;
    width: 6em;
   
    background-size: contain;
  }
  .logo-60{
    background-image: url(../../Images/airlineslogo/60.png);
    background-repeat: no-repeat;
    height: 2em;
    width: 6em;
   
    background-size: contain;
  }
  .logo-A3{
    background-image: url(../../Images/airlineslogo/A3.png);
    background-repeat: no-repeat;
    height: 2em;
    width: 6em;
  
    background-size: contain;
  }
  .logo-BA{
    background-image: url(../../Images/airlineslogo/BA.png);
    background-repeat: no-repeat;
    height: 2em;
    width: 6em;
   
    background-size: contain;
  }
  .logo-EK{
    background-image: url(../../Images/airlineslogo/EK.png);
    background-repeat: no-repeat;
    height: 2em;
    width: 6em;
  
    background-size: contain;
  }

  .logo-GF{
    background-image: url(../../Images/airlineslogo/GF.png);
    background-repeat: no-repeat;
    height: 2em;
    width: 6em;
   
    background-size: contain;
  }

  .logo-J9{
    background-image: url(../../Images/airlineslogo/J9.png);
    background-repeat: no-repeat;
    height: 2em;
    width: 6em;
    
    background-size: contain;
  }

  .logo-KL{
    background-image: url(../../Images/airlineslogo/KL.png);
    background-repeat: no-repeat;
    height: 2em;
    width: 6em;
   
    background-size: contain;
  }
  .logo-KU{
    background-image: url(../../Images/airlineslogo/KU.png);
    background-repeat: no-repeat;
    height: 2em;
    width: 6em;
    
    background-size: contain;
  }
  .logo-ME{
    background-image: url(../../Images/airlineslogo/ME.png);
    background-repeat: no-repeat;
    height: 2em;
    width: 6em;
   
    background-size: contain;
  }
  .logo-MS{
    background-image: url(../../Images/airlineslogo/MS.png);
    background-repeat: no-repeat;
    height: 2em;
    width: 6em;
   
    background-size: contain;
  }

  .logo-RJ{
    background-image: url(../../Images/airlineslogo/RJ.png);
    background-repeat: no-repeat;
    height: 2em;
    width: 6em;
  
    background-size: contain;
  }

  .logo-SV{
    background-image: url(../../Images/airlineslogo/SV.png);
    background-repeat: no-repeat;
    height: 2em;
    width: 6em;
    
    background-size: contain;
  }

  .logo-TK{
    background-image: url(../../Images/airlineslogo/TK.png);
    background-repeat: no-repeat;
    height: 2em;
    width: 6em;
  
    background-size: contain;
  }
  .logo-WY{
    background-image: url(../../Images/airlineslogo/WY.png);
    background-repeat: no-repeat;
    height: 2em;
    width: 6em;
   
    background-size: contain;
  }
  .logo-XY{
    background-image: url(../../Images/airlineslogo/XY.png);
    background-repeat: no-repeat;
    height: 2em;
    width: 6em;
   
    background-size: contain;
  }